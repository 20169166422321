import { Directive } from '@angular/core';
import { Validator, AbstractControl, NG_VALIDATORS } from '@angular/forms';

declare var intlTelInputUtils: any;

export function ValidatePhone(control: AbstractControl): { [key: string]: any } | null {
  if (!control.value) {
    return null;
  }
  if (typeof control.value == "string") {
    let number = control.value.replace(/-/g, "");
    if (!number.startsWith('+')) {
      number = '+' + number;
    }
    if (intlTelInputUtils && intlTelInputUtils.isValidNumber(number)) {
      return null;
    }
  }
  return { 'phoneNumberInvalid': true };
}

@Directive({
  selector: '[epPhoneValidator]',
  providers: [{
    provide: NG_VALIDATORS,
    useExisting: PhoneValidatorDirective,
    multi: true
  }]
})
export class PhoneValidatorDirective implements Validator {
  validate(control: AbstractControl): { [key: string]: any } | null {
    return ValidatePhone(control);
  }
}
