export enum AccountApis {
    updateProfile = 'members/update-profile',
    UpdateProfilePicture = 'members/add-profile-picture',
    changePassword = 'members/auth/change-password',
    getSavedSearches = 'property/getSearchFilters',
    deleteSearchFilter = 'property/deleteSearchFilter',
    getWishlist = 'property/getWishlist',
    removeFromWishlist = 'property/removePropertyFromWishlist',
    requestEmailVerification = 'members/auth/request-verify-email'
}
