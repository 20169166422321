import { Component, OnInit, Inject } from '@angular/core';
import { MAT_SNACK_BAR_DATA, MatSnackBarRef } from '@angular/material/snack-bar';
import { FlashCardData, FlashMessageType } from './flash-message.constants';

@Component({
  selector: 'app-flash-message',
  templateUrl: './flash-message.component.html',
  styleUrls: ['./flash-message.component.scss']
})
export class FlashMessageComponent implements OnInit {
  constructor(@Inject(MAT_SNACK_BAR_DATA) public data: FlashCardData, private _snackRef: MatSnackBarRef<FlashMessageComponent>) { }

  ngOnInit(): void {
  }

  close() {
    this._snackRef.dismiss();
  }

}
