import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-call-dilog',
  templateUrl: './call-dilog.component.html',
  styleUrls: ['./call-dilog.component.scss']
})
export class CallDilogComponent implements OnInit {
  phoneNumber: any = null;
  reference: any = null;
  constructor(public dialogRef: MatDialogRef<CallDilogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
    this.phoneNumber = data.phoneNumber;
    this.reference = data.reference;
  }



  ngOnInit(): void {
  }

}
