<h2 mat-dialog-title style="text-align: center;font-weight: bold;">Contact Us</h2>
<div class="details-wrapper details-wrapper-top">
    <img style="width: 100px;height: auto;margin: auto" *ngIf="contactData.company"
        [src]="imageBaseUrl + contactData.company.logo">
    <div class="content">
        <p>{{contactData.property.title}}</p>
        <p>{{contactData.property.reference}}</p>
        <p>{{contactData.property.trakheesi_permit}}</p>
        <p *ngIf="contactData.agent">Agent: <a
                [routerLink]="['/properties/agent/' + contactData.agent.id]">{{contactData.agent.name}}</a></p>
        <p *ngIf="contactData.member">Member: <a href="#">{{contactData.member.full_name}}</a></p>
    </div>
    <form class="contact-form" [formGroup]="contactForm" (ngSubmit)="sendEmail()">
        <mat-form-field class="form-field">
            <mat-label>Full Name*</mat-label>
            <input matInput formControlName="full_name">
            <mat-error *ngIf="contactFormControl.full_name.hasError('required')">
                Full name is <strong>required</strong>
            </mat-error>
        </mat-form-field>
        <mat-form-field class="form-field">
            <mat-label>Mobile *</mat-label>
            <ep-phone-input formControlName="mobile"></ep-phone-input>
            <mat-error *ngIf="contactFormControl.mobile.touched && contactFormControl.mobile.invalid">Mobile number is
                <strong>invalid</strong>
            </mat-error>
        </mat-form-field>
        <mat-form-field class="form-field">
            <mat-label>Email*</mat-label>
            <input matInput formControlName="email">
            <mat-error
                *ngIf="contactFormControl.email.hasError('email') && !contactFormControl.email.hasError('required')">
                Please enter a valid email address
            </mat-error>
            <mat-error *ngIf="contactFormControl.email.hasError('required')">
                Email is <strong>required</strong>
            </mat-error>
        </mat-form-field>
        <mat-form-field class="form-field">
            <mat-label>Your Message*</mat-label>
            <textarea matInput rows="10" formControlName="message"></textarea>
            <mat-error *ngIf="contactFormControl.message.hasError('required')">
                Full name is <strong>required</strong>
            </mat-error>
        </mat-form-field>

        <div class="actions">
            <button type="button" *ngIf="!isNumberShown" (click)="showNumber()" class="call" mat-flat-button
                color="primary">
                <mat-icon>local_phone</mat-icon> Call
            </button>
            <a [href]="'tel:' + ((contactData.agent) ? contactData.agent.phone : contactData.member.mobile)"
                *ngIf="isNumberShown" class="call" mat-flat-button color="primary">
                {{ (contactData.agent) ? contactData.agent.phone : contactData.member.mobile}}
            </a>
            <button type="submit" class="email" mat-stroked-button>
                <mat-icon color="primary">email</mat-icon> Send email
            </button>
        </div>

    </form>
</div>