<form [formGroup]="form">

    <h2 mat-dialog-title>Edit Caption</h2>
    <div mat-dialog-content>
        <mat-form-field class="form-field" style="width: 100%">
            <mat-label>Caption</mat-label>
            <input matInput formControlName="caption">
        </mat-form-field>
    </div>
    <div mat-dialog-actions align="end">
        <button mat-button mat-dialog-close>Cancel</button>
        <button mat-raised-button type="submit" color="primary" (click)="save()" cdkFocusInitial>Save</button>
    </div>
</form>