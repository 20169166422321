import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { FlashMessageComponent } from 'src/app/shared/components/flash-message/flash-message.component';
import { FlashMessageType } from 'src/app/shared/components/flash-message/flash-message.constants';

@Injectable({
  providedIn: 'root'
})
export class ResponseHandlerService {

  constructor(private _snackBar: MatSnackBar) { }

  handleError(error) {
    let message = 'Something went wrong, try again later.';
    if (error.error && error.error.message) message = error.error.message;
    else if (error.error && error.error.error) message = error.error.error;
    else if (error.message) message = error.message;
    this._snackBar.openFromComponent(FlashMessageComponent, {
      duration: 5000,
      panelClass: [FlashMessageType.red],
      data: { title: 'Error', text: message },
      horizontalPosition: 'center',
      verticalPosition: 'top',
    });
  }

  showMessage(title: string, text: string, type: FlashMessageType) {
    this._snackBar.openFromComponent(FlashMessageComponent, {
      duration: 5000,
      panelClass: [type],
      data: { title, text },
      horizontalPosition: 'center',
      verticalPosition: 'top',
    });
  }

  handleSuccess(message) {
    this.showMessage('Success', message, FlashMessageType.green);
  }
}
