import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable, of } from 'rxjs';
import { GeneralDataStoreService } from 'src/app/core/services/general-data-store.service';
import { AuthService } from '../services/auth.service';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class GeneralResolverService implements Resolve<any> {

  constructor(private authService: AuthService, private generalDataStoreService: GeneralDataStoreService) { }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Observable<never> {
    if (this.generalDataStoreService.getStateLatestSnapshot().loaded) return of(null);
    return this.authService.me().pipe(map(response => {
      this.generalDataStoreService.loadDataSubject(response);
      if (response.user) this.authService.setAuthenticatedUser({ access_token: this.authService.getStateLatestSnapshot().access_token, user: response['user'] });
    }));
  }
}
