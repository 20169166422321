<h2 mat-dialog-title style="text-align: center;font-weight: bold;">Contact Us</h2>
<div>

    <mat-dialog-content class="mat-typography">
        <div style="display: flex;align-items: center;text-align: center;margin-bottom: .5rem">
            <div>
                <mat-icon color="primary" style="font-size: 2rem">phone_in_talk</mat-icon>
            </div>
            <div style="font-size: 1.5rem;flex: 1;"><a [href]="'tel:' + phoneNumber">{{phoneNumber}}</a></div>
        </div>
        <mat-divider></mat-divider>
        <p style="font-size: 1rem;margin-top: 1rem;text-align: center;margin-bottom: 0;">Please quote property reference</p>
        <p style="font-size: 1rem;text-align: center;margin-bottom: 0;font-weight: bold;">{{reference}}</p>
        <p style="font-size: 1rem;text-align: center;margin-bottom: 0;">when calling us.</p>
    </mat-dialog-content>
</div>

<button class="close" mat-icon-button mat-dialog-close>
    <mat-icon>close</mat-icon>
</button>