<div #container cellspacing="0" (click)="onTouched()" class="phone-input" style="display: flex;">
    <div>
        <button type="button" [matMenuTriggerFor]="languageMenu" class="phone-input-select">
            <span> <img [src]="selectedCountry.icon"> {{ selectedCountry.label }}</span>
            <mat-icon color="primary"> expand_more</mat-icon>
        </button>

        <mat-menu #languageMenu="matMenu">
            <button *ngFor="let country of countryList" mat-menu-item (click)="selectCountry(country.value)">
                <span><img [src]="country.icon"> {{ country.label }} </span>
            </button>
        </mat-menu>
    </div>
    <div style="width: 100%;">
        <input matInput [ngModel]="_value" (input)="changeInput($event.target.value)">
    </div>
</div>