export enum AuthApis {
    normalLogin = 'members/auth/login',
    registerMember = 'members/auth/register',
    logout = 'members/auth/logout',
    me = 'index',
    changePassword = 'members/auth/change-password',
    forgetPassword = 'members/auth/forget-password',
    resetPassword = 'members/auth/reset-password',
    verifyEmail = 'members/auth/verify-email',
    googleLogin = 'members/auth/google/login',
    facebookLogin = 'members/auth/facebook/login'
}


export interface User {
    active: boolean;
    address: string;
    email: string;
    full_name: string;
    id: number;
    is_email_verified: boolean;
    is_mobile_verified: boolean;
    mobile: string;
    profile_photo: string;
    update_profile: boolean;
    whatsapp_number: string;
}

export interface UserState {
    user: User;
    access_token: string;
}