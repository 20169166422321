import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, ReplaySubject, throwError, BehaviorSubject } from 'rxjs';
import { AuthApis, UserState } from '../auth.constants';
import { map, tap, catchError } from 'rxjs/operators';
import { UserTokenService } from './user-token.service';
import { environment } from 'src/environments/environment';
import { GeneralDataStoreService } from 'src/app/core/services/general-data-store.service';
import { ResponseHandlerService } from 'src/app/core/services/response-handler.service';
import { FlashMessageType } from 'src/app/shared/components/flash-message/flash-message.constants';

let _userState: UserState = {
  user: null,
  access_token: UserTokenService.getToken() ? UserTokenService.getToken() : null,
}

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private userStore = new BehaviorSubject<UserState>(_userState);
  public userState$ = this.userStore.asObservable();

  constructor(private httpClient: HttpClient, private generalUserData: GeneralDataStoreService, private responseHandlerService: ResponseHandlerService) { }

  login(user: any): Observable<{}> {
    return this.httpClient.post(AuthApis.normalLogin, user).pipe(map((response: UserState) => {
      UserTokenService.storeToken(response['access_token']);
      this.userStore.next(response);
      return response;
    }));
  }

  googlelogin(user: any): Observable<{}> {
    return this.httpClient.post(AuthApis.googleLogin, user).pipe(map((response: UserState) => {
      UserTokenService.storeToken(response['access_token']);
      this.userStore.next(response);
      return response;
    }), catchError(err => {
      if (err.status == 400) {
        this.responseHandlerService.showMessage('Warning', 'This Email has already been taken.', FlashMessageType.orange);
        return
      }
      this.responseHandlerService.handleError(err);
      return throwError(err);
    }));
  }


  Facebooklogin(user: any): Observable<{}> {
    return this.httpClient.post(AuthApis.facebookLogin, user).pipe(map((response: UserState) => {
      UserTokenService.storeToken(response['access_token']);
      this.userStore.next(response);
      return response;
    }), catchError(err => {
      if (err.status == 400) {
        this.responseHandlerService.showMessage('Warning', 'This Email has already been taken.', FlashMessageType.orange);
        return
      }
      this.responseHandlerService.handleError(err);
      return throwError(err);
    }));
  }

  forgetPassword(data: any): Observable<{}> {
    return this.httpClient.post(AuthApis.forgetPassword, data);
  }

  resetPassword(data: any): Observable<{}> {
    return this.httpClient.post(AuthApis.resetPassword, data).pipe(map((response: any) => {
      this.responseHandlerService.showMessage('Success', response.message, FlashMessageType.green);
      return response;
    }));
  }

  verifyEmail(data: any): Observable<{}> {
    return this.httpClient.post(AuthApis.verifyEmail, data).pipe(map((response: any) => {
      this.responseHandlerService.showMessage('Success', response.message, FlashMessageType.green);
      return response;
    }, catchError(err => {
      if (err.status == 400) {
        this.responseHandlerService.showMessage('Warning', 'Provided link has expired', FlashMessageType.orange);
        return
      }
      this.responseHandlerService.handleError(err)
      return throwError(err);
    })));
  }

  logout() {
    return this.httpClient.post(AuthApis.logout, {}).pipe(tap(response => {
      UserTokenService.logOut();
      this.userStore.next({ user: null, access_token: null });
    }));
  }

  clearUserData() {
    UserTokenService.logOut();
    this.userStore.next({ user: null, access_token: null });
  }

  signup(user: any): Observable<{}> {
    return this.httpClient.post(AuthApis.registerMember, user).pipe(map((response: UserState) => {
      UserTokenService.storeToken(response['access_token']);
      this.userStore.next(response);
      return response;
    }));
  }

  me(): Observable<any> {
    return this.httpClient.get(AuthApis.me).pipe(map((response: any) => {
      response.loaded = true;
      response.type_list_categories = response.type_list_categories.map(category => {
        category.childrenTypes = response.type_list.filter(value => value.category_id == category.id);
        return category;
      });
      response.amenities_categories = response.amenities_categories.map(amenityCategory => {
        amenityCategory.amenities = response.amenities.filter(value => +value.category_id === +amenityCategory.id);
        return amenityCategory;
      }).filter(value => (value && value.amenities && Array.isArray(value.amenities) && value.amenities.length > 0));
      if (response['user'] && response.user.profile_photo && !response.user.profile_photo.startsWith('http')) {
        response.user.profile_photo = environment.imageBaseUrl + response.user.profile_photo;
      }
      return response;
    }));
  }

  setAuthenticatedUser(user: UserState) {
    this.userStore.next(user);
  }

  getStateLatestSnapshot(): UserState {
    return this.userStore.getValue();
  }

  setDataSubject(value) {
    this.userStore.next(value);
  }

  getDataSubject() {
    return this.userState$;
  }
}
