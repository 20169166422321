import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-property-card',
  templateUrl: './property-card.component.html',
  styleUrls: ['./property-card.component.scss']
})
export class PropertyCardComponent implements OnInit {
  @Input() propertyDetails: any;
  @Input() agent: any;
  @Output() clickWishlist = new EventEmitter<any>();
  @Output() call = new EventEmitter();
  @Output() email = new EventEmitter();
  imageBaseUrl = environment.imageBaseUrl;

  constructor() { }

  ngOnInit(): void {
    if (this.agent) {
      this.propertyDetails.agent = this.agent;
    }
  }

}
