<div class="card">
    <div class="card-link">
        <a [routerLink]="['/properties/' + propertyDetails.id]"></a>
    </div>
    <div class="card-image">
        <div class="card-image__property-image"
            [style.background-image]="'url(' + imageBaseUrl + propertyDetails.images[0].path + ')'">
            <img [src]="imageBaseUrl + propertyDetails?.images[0]?.path" style="visibility: hidden;"
                [alt]=" propertyDetails?.images[0]?.name">
        </div>
    </div>
    <div class="card-contents">
        <div class="card-details">
            <div class="card-details-price">{{propertyDetails.price | currency: 'AED'}}
                <span *ngIf="propertyDetails.completion_status"
                    class="card-details-price-frequency">{{propertyDetails.frequencyName}}</span>
                <span *ngIf="propertyDetails.completionStatusName"
                    class="card-details-price-frequency">{{propertyDetails.completionStatusName}}</span>
            </div>
            <div class="card-details-title">{{propertyDetails.title}}</div>
            <div class="card-details-location">
                <mat-icon>location_on</mat-icon> {{propertyDetails.city_name}}
            </div>
            <div class="card-details-amenities">
                <div class="amenity-item">
                    {{propertyDetails.type?.name}}
                </div>
                <div class="amenity-item">
                    <img src="assets/images/bed.svg" alt="Bed"> {{propertyDetails.bedrooms}} <span
                        class="amenity-label">Beds</span>
                </div>
                <div class="amenity-item">
                    <img src="assets/images/bathtub.svg" alt="Bath"> {{propertyDetails.bathrooms}} <span
                        class="amenity-label">Baths</span>
                </div>
                <div class="amenity-item">
                    <img src="assets/images/dimensions.svg" alt="dimensions">
                    {{propertyDetails.area}} sqft
                </div>
            </div>
            <div class="card-details-actions">
                <button (click)="call.emit(propertyDetails)" mat-stroked-button color="accent">
                    <mat-icon color="primary">local_phone</mat-icon> Call
                </button>
                <button (click)="email.emit(propertyDetails)" mat-stroked-button color="accent">
                    <mat-icon color="primary">email</mat-icon> Email
                </button>
                <button
                    (click)="clickWishlist.emit({property: propertyDetails, wishlistAdded: propertyDetails.wishlist})"
                    mat-stroked-button color="accent">
                    <mat-icon color="primary">{{ propertyDetails.wishlist? 'favorite':'favorite_outlined'}}</mat-icon>
                    {{ propertyDetails.wishlist? 'Saved':'Save'}}
                </button>
            </div>
        </div>
        <div class="card-extra">
            <img *ngIf="propertyDetails.agent" [src]="imageBaseUrl + propertyDetails.agent.company.logo" alt="JSK">
        </div>
    </div>
</div>