import { Injectable, Inject, PLATFORM_ID } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { environment } from 'src/environments/environment';
import { UserTokenService } from 'src/app/auth/services/user-token.service';
import { catchError, tap } from 'rxjs/operators';
import { ResponseHandlerService } from '../services/response-handler.service';
import { isPlatformBrowser } from '@angular/common';
import { AccountApis } from 'src/app/features/profile/modules/account/account.constants';
import { AuthApis } from 'src/app/auth/auth.constants';
import { CoreApis } from '../core.constants';

const apisToIgnore = [
  AccountApis.requestEmailVerification,
  AuthApis.verifyEmail,
  CoreApis.addToWishlist,
  CoreApis.removeFromWishlist,
  AccountApis.removeFromWishlist,
  AuthApis.googleLogin,
  AuthApis.facebookLogin
]


@Injectable()
export class ApiInterceptor implements HttpInterceptor {

  constructor(private responseHandlerService: ResponseHandlerService, @Inject(PLATFORM_ID) private platformId: Object) { }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    request = request.clone({
      url: `${environment.baseURL}${request.url}`,
      setHeaders: {
        Authorization: (isPlatformBrowser(this.platformId) && UserTokenService.getToken()) ? 'Bearer ' + UserTokenService.getToken() : '',
      }
    });
    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        if (!apisToIgnore.some((val => request.url.includes(val)))) this.responseHandlerService.handleError(error);
        return throwError(error);
      }),
    );
  }
}
