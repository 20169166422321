<div class="cropper">
    <h2 mat-dialog-title>Crop and Upload Image:</h2>
    <div mat-dialog-content>
        <image-cropper *ngIf="imageChangedEvent" [imageFile]="imageChangedEvent" [maintainAspectRatio]="true"
            [aspectRatio]="aspectRatio" [resizeToWidth]="width" [format]="format" (imageCropped)="imageCropped($event)"
            (imageLoaded)="imageLoaded()" (loadImageFailed)="loadImageFailed()"></image-cropper>
    </div>
    <div mat-dialog-actions align="end">
        <button mat-button mat-dialog-close>Cancel</button>
        <button mat-raised-button type="submit" color="primary" (click)="onUpload()" cdkFocusInitial>Upload</button>
    </div>
</div>