<div style="display: flex;align-items: center;height: 100%;">
    <div class="icon-wrapper">
        <div class="icon-content"></div>
    </div>
    <div class="content-wrapper">
        <h2 class="title">{{data.title}}</h2>
        <p class="text">{{data.text}}</p>
    </div>
    <div class="action">
        <mat-icon (click)="close()">close</mat-icon>
    </div>
</div>