import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { GeneralResolverService } from './auth/resolvers/general-resolver.service';
import { LoggedInGuard } from './auth/guards/logged-in.guard';

const routes: Routes = [
  { path: '', resolve: [GeneralResolverService], loadChildren: () => import('./layout/layout.module').then(m => m.LayoutModule) },
  { path: 'auth', resolve: [GeneralResolverService], loadChildren: () => import('./auth/auth.module').then(m => m.AuthModule) }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    initialNavigation: 'enabled'
})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
