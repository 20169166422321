import { Injectable } from '@angular/core';
import { Subject, ReplaySubject, BehaviorSubject } from 'rxjs';
import { AppState } from '../core.constants';


let _state: AppState = {
  loaded: false,
  amenities: [],
  amenities_categories: [],
  cities_list: [],
  completion_status: [],
  contact_info: null,
  cookies: null,
  countries_list: [],
  frequency_list: [],
  imageBaseUrl: null,
  max_area: null,
  max_price: null,
  min_area: null,
  min_price: null,
  purpose_list: [],
  social_links: null,
  type_list: [],
  type_list_categories: [],
}

@Injectable({
  providedIn: 'root'
})
export class GeneralDataStoreService {
  private appStore = new BehaviorSubject<AppState>(_state);
  private appState$ = this.appStore.asObservable();

  constructor() { }

  getStateLatestSnapshot(): AppState {
    return this.appStore.value;
  }

  setDataSubject(value) {
    this.appStore.next(value);
  }

  loadDataSubject(value) {
    this.appStore.next(value);
  }

  getDataSubject() {
    return this.appState$;
  }
}
