<div class="img-ul" [ngClass]="{'img-ul-file-is-over': fileOver}">
    <div class="img-ul-file-upload img-ul-hr-inline-group">
        <label *ngIf="fileCounter != max" class="img-ul-button" [ngClass]="{'img-ul-disabled': disabled}">
            <div><img [src]="'assets/images/'+ captionType +'-upload.svg'" alt=""></div>
            <div class="upload-text">Upload {{captionType === 'image'? 'Images': 'Video'}}</div>
            <input type="file" [disabled]="disabled" [accept]="supportedExtensions" [multiple]="(max > 1)"
                (change)="onFileChange(input.files)" #input>
        </label>
    </div>

    <!-- <p class="img-ul-file-too-large" *ngIf="showFileTooLargeMessage" [innerText]="fileTooLargeMessage"></p> -->

    <div *ngIf="preview" class="img-ul-container img-ul-hr-inline-group example-list"
        cdkDropListOrientation="horizontal" cdkDropList (cdkDropListDropped)="drop($event)">


        <div class="img-ul-image draggable-elements" *ngFor="let file of files;let i = index"
            [ngStyle]="{'background-image': 'url('+ file.src +')'}" [ngClass]="{'img-ul-first': (i == 0 && firstMain)}"
            cdkDrag>

            <video *ngIf="!file.pending && supportedExtensions[0].includes('video')" controls>
                <source [src]="file.src" type="video/mp4">
                <source [src]="file.src" type="video/ogg">
                Your browser does not support the video tag.
            </video>

            <div *ngIf="file.pending" class="img-ul-loading-overlay">
                <div class="img-ul-spinning-circle"></div>
            </div>
            <div class="img-actions">
                <div title="Add Caption" *ngIf="!file.pending && allowCaption" [ngClass]="{'img-ul-disabled': disabled}"
                    class="img-ul-caption" (click)="addCaption(file)">
                    <i class="material-icons"> add_photo_alternate </i>
                </div>
                <div title="Delete Image" *ngIf="!file.pending" [ngClass]="{'img-ul-disabled': disabled}"
                    (click)="deleteFile(file)" class="img-ul-delete">
                    <i class="material-icons"> delete_forever </i>
                </div>
            </div>
        </div>
    </div>

    <div style="padding: 4px;padding-right: 16px;padding-left: 16px"
        *ngIf="readyToUpload.length > 0 && fileCounter != max && crop">
        <hr>
        <p style="padding-bottom: 8px;margin: 0">Select Images To Crop and Upload</p>
        <div *ngFor="let blobObject of readyToUpload;let i = index"
            style="display: inline-block;position: relative;margin-right: 8px;">
            <img matTooltip="Crop and Upload Image" (click)="openUploadImageDialog( blobObject.file, i)"
                [src]="blobObject.src" alt="pending upload" width="100" height="100" class="ready-top-upload">
            <div title="remove pending Image" (click)="deletePendingFile(i)" class="img-ul-delete"
                style="position: absolute;bottom: 0;right: 40%;cursor: pointer;">
                <i class="material-icons"> delete_forever </i>
            </div>
        </div>
    </div>
</div>