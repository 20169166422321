import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MaterialModule } from './material/material.module';
import { BreadcrumbComponent } from './components/breadcrumb/breadcrumb.component';
import { ImageComponent } from './components/image/image.component';
import { ImageCropperModule } from 'ngx-image-cropper';
import { RouterModule } from '@angular/router';
import { PropertyCardComponent } from './components/property-card/property-card.component';
import { CallDilogComponent } from './components/call-dilog/call-dilog.component';
import { ImageUploaderComponent } from './components/image-uploader/image-uploader.component';
import { CaptionComponent } from './components/caption/caption.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FlashMessageComponent } from './components/flash-message/flash-message.component';
import { EmailDialogComponent } from './components/email-dialog/email-dialog.component';
import { PhoneInputComponent } from './phone-input/phone-input.component';
import { PhoneValidatorDirective } from './utils/phone-validator.directive';

@NgModule({
  declarations: [BreadcrumbComponent, ImageComponent, PropertyCardComponent, CallDilogComponent, ImageUploaderComponent, CaptionComponent, FlashMessageComponent, EmailDialogComponent, PhoneInputComponent, PhoneValidatorDirective],
  imports: [
    CommonModule,
    ImageCropperModule,
    MaterialModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule
  ],
  exports: [
    MaterialModule,
    BreadcrumbComponent,
    PropertyCardComponent,
    ImageUploaderComponent,
    PhoneInputComponent,
    PhoneValidatorDirective
  ]
})
export class SharedModule { }
