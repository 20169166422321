export enum CoreApis {
    addToWishlist = 'property/addPropertyToWishlist',
    removeFromWishlist = 'property/removePropertyFromWishlist',
    uploadPhoto = 'front/upload-photo',
    uploadFile = 'front/upload-video'
}

export interface AppState {
    loaded: boolean;
    amenities: any;
    amenities_categories: any;
    cities_list: any;
    completion_status: any;
    contact_info: any;
    cookies: any;
    countries_list: any;
    frequency_list: any;
    imageBaseUrl: string;
    max_area: number;
    max_price: number;
    min_area: number;
    min_price: number;
    purpose_list: any;
    social_links: any;
    type_list: any;
    type_list_categories: any;
}