import { Component, OnInit, Inject, Input } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { environment } from 'src/environments/environment';
import { FormBuilder, Validators } from '@angular/forms';
import { ValidatePhone } from '../../utils/phone-validator.directive';
import { Agent, Company, Member } from 'src/app/features/properties/properties.constants';


export interface ContactCard {
  agent?: Agent;
  member?: Member;
  property: { title: string, reference: string, trakheesi_permit: string };
  company?: Company;
}

@Component({
  selector: 'app-email-dialog',
  templateUrl: './email-dialog.component.html',
  styleUrls: ['./email-dialog.component.scss']
})
export class EmailDialogComponent implements OnInit {
  contactForm = this.fb.group({
    full_name: ['', [Validators.required]],
    email: ['', [Validators.required, Validators.email]],
    message: ['', Validators.required],
    mobile: ['', Validators.compose([Validators.required, ValidatePhone])],
  })
  isNumberShown = false;
  contactData: ContactCard;
  mobile: any;
  imageBaseUrl = environment.imageBaseUrl;
  constructor(public dialogRef: MatDialogRef<EmailDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any, private fb: FormBuilder) {
    this.contactData = data;
  }

  ngOnInit(): void {
  }

  get contactFormControl() {
    return this.contactForm.controls;
  }

  showNumber() {
    this.isNumberShown = true;
  }

  sendEmail() {
    this.contactForm.markAllAsTouched();

    if (this.contactForm.valid) {
      let email = '';
      if (this.contactData.agent) {
        email = this.contactData.agent.email;
      } else {
        email = this.contactData.member.email;
      }
      location.href = "mailto:" + email + '&subject=Property with ref: ' + this.contactData.property.reference + '&body=' + this.contactFormControl.message.value + '%0D%0A%0D%0Acontact info:%0D%0AName: ' + this.contactFormControl.full_name.value + '%0D%0AMobile number:' + this.contactFormControl.mobile.value + '%0D%0AEmail: ' + this.contactFormControl.email.value;
    }
  }
}
