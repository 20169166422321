import { Component, OnInit, forwardRef, OnChanges, SimpleChanges, OnDestroy, Injector, DoCheck, Input, ElementRef, HostBinding, ViewChild } from '@angular/core';
import { NG_VALUE_ACCESSOR, ControlValueAccessor, NgControl } from '@angular/forms';
import { MatFormFieldControl } from '@angular/material/form-field';
import { Observable, Subject } from 'rxjs';
import { FocusMonitor } from '@angular/cdk/a11y';
import { coerceBooleanProperty } from '@angular/cdk/coercion';

declare var intlTelInputUtils: any;

@Component({
  selector: 'ep-phone-input',
  templateUrl: './phone-input.component.html',
  styleUrls: ['./phone-input.component.scss'],
  host: {
    '[id]': 'id',
    '[attr.aria-describedby]': 'describedBy'
  },
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => PhoneInputComponent),
    multi: true
  },
  {
    provide: MatFormFieldControl,
    useExisting: PhoneInputComponent
  }]
})
export class PhoneInputComponent implements OnInit, OnDestroy, ControlValueAccessor, MatFormFieldControl<any> {
  countryList = [{ icon: 'assets/images/uae-flag.png', label: '+971', value: '+971', placeholder: '501234567' }];
  selectedCountry: any = this.countryList[0];
  _value: string;
  stateChanges = new Subject<void>();
  ngControl: NgControl;
  focused: boolean;
  controlType = 'phoneinput';
  autofilled?: boolean;
  userAriaDescribedBy?: string;
  @ViewChild('container', { read: ElementRef }) container: ElementRef;

  @HostBinding('attr.aria-describedby') describedBy = '';
  setDescribedByIds(ids: string[]) {
    this.describedBy = ids.join(' ');
  }

  get empty() {
    return (!this.selectedCountry || !this.selectedCountry.value) || !this._value;
  }

  @HostBinding('class.floating')
  get shouldLabelFloat() {
    return this.focused || !this.empty;
  }


  static nextId = 0;
  @HostBinding() id = `phone-input-${PhoneInputComponent.nextId++}`;

  @Input()
  get disabled() {
    return this._disabled;
  }
  set disabled(dis) {
    this._disabled = coerceBooleanProperty(dis);
    this.stateChanges.next();
  }
  public _disabled = false;

  @Input()
  get required() {
    return this._required;
  }
  set required(req) {
    this._required = coerceBooleanProperty(req);
    this.stateChanges.next();
  }
  public _required = false;

  @Input()
  get placeholder() {
    return this._placeholder;
  }
  set placeholder(plh) {
    this._placeholder = plh;
    this.stateChanges.next();
  }
  public _placeholder: string;

  get value(): any {
    return this.selectedCountry.value + this._value;
  }
  set value(data) {
    if (intlTelInputUtils.isValidNumber(data) && this.countryList.find(country => country.value == intlTelInputUtils.formatNumber(data, null, intlTelInputUtils.numberFormat.INTERNATIONAL).split(' ')[0])) {
      this.selectedCountry = this.countryList.find(country => country.value == intlTelInputUtils.formatNumber(data, null, intlTelInputUtils.numberFormat.INTERNATIONAL).split(' ')[0]);
      this._value = data.slice(this.selectedCountry.value.length)
    } else {
      this.selectedCountry = this.countryList[0];
      this._value = '';
    }
    this.stateChanges.next();
  }

  constructor(public elRef: ElementRef, public injector: Injector, private fm: FocusMonitor) {
    fm.monitor(elRef.nativeElement, true).subscribe(origin => {
      this.focused = !!origin;
      this.stateChanges.next();
    });
  }


  onContainerClick(event: MouseEvent) {
    if ((event.target as Element).tagName.toLowerCase() != 'input') {
      this.container.nativeElement.querySelector('input').focus();
    }
  }

  onChange: any = () => { };
  onTouched: any = () => { };


  writeValue(data: any): void {
    if (intlTelInputUtils.isValidNumber(data) && this.countryList.find(country => country.value == intlTelInputUtils.formatNumber(data, null, intlTelInputUtils.numberFormat.INTERNATIONAL).split(' ')[0])) {
      this.selectedCountry = this.countryList.find(country => country.value == intlTelInputUtils.formatNumber(data, null, intlTelInputUtils.numberFormat.INTERNATIONAL).split(' ')[0]);
      this._value = data.slice(this.selectedCountry.value.length)
    } else {
      this.selectedCountry =  this.countryList[0];
      this._value = data;
    }
    setTimeout(() => { this.onChange(this.selectedCountry.value + this._value); }, 0);
  }
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }
  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  ngOnInit(): void {
    this.ngControl = this.injector.get(NgControl);
    if (this.ngControl != null) { this.ngControl.valueAccessor = this; }
    // this.selectedCountry = this.countryList[0];
    // this.onChange(this.selectedCountry.value + this._value);
  }

  selectCountry(value) {
    this.selectedCountry = this.countryList.find(val => val.value == value);
    this.onChange(this.selectedCountry.value + this._value);
  }
  changeInput(value) {
    this._value = value;
    this.onChange(this.selectedCountry.value + this._value);
  }

  get errorState() {
    return this.ngControl && this.ngControl.errors !== null && !!this.ngControl.touched;
  }

  ngOnDestroy() {
    this.stateChanges.complete();
    this.fm.stopMonitoring(this.elRef.nativeElement);
  }
}
