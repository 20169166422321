export class FlashCardData {
    text: string;
    title: string;
}

export enum FlashMessageType {
    white = 'flash-white',
    blue = 'flash-blue',
    green = 'flash-green',
    orange = 'flash-orange',
    red = 'flash-red'
}